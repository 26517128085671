.devices {
    display: grid;
    grid-template-columns: 50px max-content 0.3fr 50px max-content;
    grid-template-rows: repeat(3, auto);
    column-gap: 20px;
    row-gap: 20px;
    justify-content: center;
    align-items: center;

    .device {
        display: contents;
    }

    span {
        text-align: right;
    }
}
