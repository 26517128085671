.sitesVars {
    --header-row-border-top: 1px solid var(--pr-color-grayscale-50);
    --header-row-border-bottom: 1px solid var(--pr-color-grayscale-50);
    --header-row-background-color: var(--pr-color-grayscale-white);
    --header-cell-box-hover-background-color: unset;
    --body-nth-child-even-background-color: var(--pr-color-grayscale-20);
    --body-hover-background-color: var(--pr-color-grayscale-10);
}

.headerRow {
    border-top: var(--header-row-border-top);
    border-bottom: var(--header-row-border-bottom);
    background-color: var(--header-row-background-color);

    .headerCell {
        font-weight: bold;
        margin: auto;
    }

    .headerCellBox:hover {
        background-color: var(--header-cell-box-hover-background-color);
    }
}

.body {
    > div:nth-child(even) {
        background-color: var(--body-nth-child-even-background-color);
    }

    > div:hover {
        background-color: var(--body-hover-background-color);
    }

    .rowCell {
        margin: auto;
    }

    .siteLink {
        word-break: break-word;
    }
}
