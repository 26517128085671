.welcome {
    width: 100%;
    height: 100%;

    .form {
        margin: auto auto;
        width: fit-content;
    }

    h1 {
        text-align: center;
    }


    .field {
        margin-bottom: 10px;

        & > label {
            min-width: 120px;
            margin-right: 50px;
        }
    }

    .error {
        color: var(--colorPaletteRedForeground1);
    }
}
