.trafficLight {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    font-weight: bold;
    display: flex;
    align-items: center;
}

.icon {
    flex: 0 0 40px;
}

.text {
    flex: 1 1 auto;
}
