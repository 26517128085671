* {
    font-family: 'Manrope', sans-serif;
}

.col-75 {
    flex: 75% 0 0;
}

.col-33 {
    flex: 33% 0 0;
}

.col-25 {
    flex: 25% 0 0;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.font-style-italic {
    font-style: italic;
}

.mx-auto {
    margin: 0 auto;
}

.page {
    position: relative;
    width: calc(100% - 10px);
    min-height: 100vh;
    margin-left: 5px;

    & > .progress-bar {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
}

.dark {
    .ms-Nav-compositeLink {
        &.is-selected {
            .ms-Button {
                background-color: var(--pr-color-grayscale-90);
            }
        }

        &:not(.is-selected):hover {
            .ms-Button {
                background-color: var(--pr-color-grayscale-70);
            }
        }
    }
}
