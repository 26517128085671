.bannerVars {
    --banner-background-color: rgba(255, 255, 255, 0.75);
}

.banner {
    position: relative;

    .content {
        position: relative;
        height: auto;
        width: 100%;
        padding: 5px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: var(--banner-foreground-color, black);
    }
}

.imgBanner {
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: var(--banner-background-color);
    }
}
