.tabVars {
    --overview-link-color: #0bae9a;
    --communication-link-color: #007cd9;
    --collaboration-link-color: #7030a0;
    --onedrive-link-color: #0c82db;
    --sharepoint-link-color: #459699;
    --devices-link-color: #000000;
    --inactive-link-color: var(--pr-color-grayscale-40);

    --selected-background-color: #ffffff;

    --sidenav-menu-icon-color: rgb(238, 238, 238);
    --sidenav-background-color: rgb(31, 31, 31);
}
