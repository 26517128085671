.equivalence {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
}

.icon {
    flex: 0 0 40px;
}

.text {
    flex: 1 1 auto;
    text-align: center;
}
