.tabVars {
    --overview-link-color: #0bae9a;
    --communication-link-color: #007cd9;
    --collaboration-link-color: #7030a0;
    --onedrive-link-color: #0c82db;
    --sharepoint-link-color: #459699;
    --devices-link-color: #000000;
    --inactive-link-color: var(--pr-color-grayscale-50);

    --selected-background-color: #ffffff;

    --sidenav-menu-icon-color: rgb(31, 31, 31);
    --sidenav-background-color: rgb(238, 238, 238);
}

.tab {
    display: flex;
    width: 100%;

    .sidenav {
        width: 186px;
        height: 100vh;
        position: fixed;
        box-sizing: border-box;
        overflow-y: auto;

        .bottom {
            position: absolute;
            margin: auto;
            left: 10px;
            right: 10px;
            bottom: 50px;
        }

        // Nav links styling
        :global(.ms-Nav-compositeLink) {
            a[title="Overview"], a[title="Overview"]:hover {
                color: var(--overview-link-color);
            }

            a[title="Communication"], a[title="Communication"]:hover {
                color: var(--communication-link-color);
            }

            a[title="Collaboration"], a[title="Collaboration"]:hover {
                color: var(--collaboration-link-color);
            }

            a[title="OneDrive"], a[title="OneDrive"]:hover {
                color: var(--onedrive-link-color);
            }

            a[title="SharePoint"], a[title="SharePoint"]:hover {
                color: var(--sharepoint-link-color);
            }

            a[title="Devices"], a[title="Devices"]:hover {
                color: var(--devices-link-color);
            }

            &:global(.is-selected) a {
                font-weight: var(--pr-font-weight-bold);
                background-color: var(--selected-background-color);
            }

            &:not(:global(.is-selected)) a {
                color: var(--inactive-link-color);

                &:hover {
                    background-color: #ffffff;
                    font-weight: var(--pr-font-weight-bold);
                }

                &:not(:hover) img {
                    filter: grayscale(1) brightness(1.5);
                }
            }
        }
    }

    .mobileTrigger {
        display: none;
        position: absolute;
        top: 16px;
        left: 16px;
    }

    .content {
        max-height: 100vh;
        width: 100%;
        margin-left: 186px;
        overflow: auto;
    }
}

.overviewIcon img {
    filter: none !important;
}

@media screen and (max-width: 767px) {
    .sidenav {
        z-index: 3;
        opacity: 0;
        visibility: hidden;
        background-color: var(--sidenav-background-color);
        border-right: 1px solid gray;
        transition: visibility 0.2s linear, opacity 0.2s linear;

        &.mobileVisibile {
            opacity: 1;
            visibility: visible;
        }
    }

    .mobileTrigger {
        z-index: 2;
        display: block !important;

        svg {
            fill: var(--sidenav-menu-icon-color);
        }
    }

    .content {
        margin-left: 0 !important;
    }
}
