.overviewVars {
    --learn-more-color: var(--pr-color-blue-30);
}

.bottomLeft {
    gap: 16px;
    position: absolute;
    left: 5px;
    bottom: 5px;
    line-height: 1.2em;
    text-align: left;
    font-style: italic;
}

.subSection {
    display: flex;
    flex-direction: column;
    flex: 1 1 0px;
}

.learnMore {
    color: var(--learn-more-color);
    font-weight: bold;
    font-size: 1rem;
}

@media screen and (max-width: 768px) {
    .bottomLeft {
        position: relative;
        text-align: center;
    }
}
