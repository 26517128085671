.doughnutGraph {
    position: relative;
    height: 350px;
}

.lineGraph {
    position: relative;
    height: 250px;
}

.oneDriveLink {
    color: unset;
}
